@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:400,400i,600,600i");

.header {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    height: 50vw;
    min-height: 100px;
    max-height: 750px;
    min-width: 200px;
    color: #eee;
    margin-bottom: 30px;
}
.header:after {
    content: "";
    width: 100%;
    height: 120%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.12) 40%,
            rgba(27, 32, 48, 1) 100%
    );
}
.header:before {
    content: "";
    width: 100%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    transform: translateZ(0);
    background: #1b2030 url("../../../public/images/Barglen-2.jpg") 50% 0
    no-repeat;
    background-size: 100%;
    background-attachment: fixed;
    animation: grow 300s linear 10ms infinite;
    transition: all 2.4s ease-in-out;
    z-index: -2;
}
.header a {
    color: #eee;
}

.sides,
.headerInfo {
    flex: 0 0 auto;
    width: 50%;
    margin-top: 1%;
}
.headerInfo {
    width: 100%;
    padding: 15% 10% 0 10%;
    text-align: center;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}
.author {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url(https://i.imgur.com/6DLCsZcb.jpg) center no-repeat;
    background-size: cover;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 3px;
}
.headerInfo h4,
.meta {
    font-family: "Josefin Sans", sans-serif;
    font-size: 0.7em;
}
.headerInfo h1 {
    color: aliceblue;
    font-family: "Josefin Sans", sans-serif;
}
.meta {
    font-style: italic;
}
@keyframes grow {
    0% {
        transform: scale(1) translateY(0px);
    }
    50% {
        transform: scale(1.2) translateY(-100px);
    }
}
a {
    text-decoration: none;
}
@media screen and (max-width: 700px) {
    @keyframes grow {
        0% {
            transform: scale(1) translateY(-20px);
        }
        50% {
            transform: scale(1.2) translateY(-20px);
        }
    }
    .headerInfo h1 {
        color: aliceblue;
        font-family: "Josefin Sans", sans-serif;
        font-size: 30px;
    }
    .headerInfo h4,
    .meta {
        font-family: "Josefin Sans", sans-serif;
        font-size: 0.5em;
    }
}