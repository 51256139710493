.signIn {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    background-color: #f8f9fd;
}

input[type="text"],
input[type="password"] {
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="submit"] {
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    background: #01d28e;
    border: 1px solid #01d28e;
    color: #fff;
    padding: 10px 20px;
}

input[type="submit"]:hover {
    background: #6cf0c2;
}

.button-container {
    display: flex;
    justify-content: center;
}

.login-form {
    background-color: white;
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-container {
    display: flex;
}

.error {
    color: red;
    font-size: 12px;
}

.title {
    font-size: 25px;
    margin-bottom: 20px;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 10px;
}


/******  LOGIN PAGE  ******/

.login-container {
    display: flex;
    align-items: center;
    /*background-image: url("./images/Barglen 54-46 SL 5 PB140085.jpg");*/
    height: calc(100vh - 70px);
    background-repeat: no-repeat;
    background-position: right;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 70px;
}

.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    height: 70%;
    width: 45%;
}


/* On mouse-over, add a deeper shadow */

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}


/* Add some padding inside the card container */

.login-container {
    padding-left: 7%;
    padding-right: 7%;
    height: 100%;
}

.login-container .container h1 {
    font-size: 2.5rem;
}

.login-container .container form {
    display: flex;
    height: 80%;
    flex-direction: column;
    justify-content: space-around;
    align-self: center;
}

input[type="text"],
input[type="password"] {
    padding-left: 1px;
    padding-right: 1px;
    height: 40px;
    border-radius: 5px;
    border: .5px solid rgb(143, 143, 143);
    font-size: 15px;
}

label {
    display: flex;
    flex-direction: column;
}

.login-container button {
    height: 40px;
    font-weight: bold;
    font-size: 15px;
    background-color: #F42B4B;
    color: rgb(255, 255, 255);
}

.login-container button:hover {
    background-color: rgb(151, 25, 46);
    cursor: pointer;
}

.login-container button:focus {
    outline: none !important;
}

.spinner {
    animation: spinner infinite .9s linear;
    height: 90%;
}

.spinner:focus {
    border: none;
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.form-error {
    color: #F42B4B;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .login-container {
        justify-content: center;
        background-image: none;
    }
    .card {
        width: 80%;
        align-self: center;
    }
}

@media screen and (max-width: 350px) {
    .card {
        width: 100%;
    }
}


/******  LOGIN PAGE  ******/