* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
}

.home,
.route-info,
.biwak,
.sign-in {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

.calendar {
    padding: 2%;
}

.datePicker {
    display: block !important;
    width: 100%;
}

.space {
    margin-right: 10px;
}