.table-container {
  padding: 2%;
}

.row {
  padding-bottom: 5px;
}

.btnEdit {
  padding: 0;
  border: none;
  background: none;
}

.align-center {
  margin-top: 2%;
  text-align: center;
}
h1 {
  font-family: "Lato", sans-serif;
  color: #000040;
  text-align: center;
  font-size: 41px;
  position: relative;
}
