.info {
  /*display: grid;
  grid-template-columns: 40% 60%;
  grid-auto-rows: auto;
  grid-gap: 2%;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;*/
  display: flex;
  flex-direction: row;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.flexC1 {
  width: 39%;
}
.flexGap {
  width: 2%;
}
.flexC2 {
  width: 59%;
}
.ascentImg {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.figure {
  max-width: 93%;
  margin: auto;
}

@media screen and (max-width: 1200px) {
  .info {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-columns: auto;
    grid-gap: 10px;
  }

  .flexC1 {
    width: 100%;
  }

  .flexC2 {
    width: 100%;
  }
  .ascentImg {
    max-width: 100%;
    width: 100%;
  }
  .figure {
    max-width: 100%;
  }
}