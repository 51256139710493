.routeInfoContainer {
  margin-top: 20px;
  margin-right: 10%;
  margin-left: 10%;
}
.climbinBolt {
  width: 16px;
  height: 18px;
  margin-bottom: 2px;
}

.routeSketch {
  width: 100%;
  height: auto;
  max-height: 738px;
  display: block;
  margin: auto;
}

.routeWallImage {
  width: 100%;
  height: auto;
  max-height: 738px;
  display: block;
  margin: auto;
}

.overview {
  position: block;
  max-width: 90%;
}

.wrapper {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-auto-rows: auto;
  grid-gap: 10px;
}

#slide-container {
  grid-column: 1/4;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-rows: auto;
    grid-gap: 10px;
  }
  .routeInfoContainer {
    margin-right: 7%;
    margin-left: 7%;
  }
  .pictureOne {
    grid-row: 1;
  }
  .pictureTwo {
    grid-row: 1;
    grid-column: 2;
  }
  #slide-container {
    grid-column: 2;
  }
}

@media screen and (max-width: 769px) {
  .wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-auto-columns: auto;
    grid-gap: 10px;
  }
  .description {
    grid-row: 1;
  }
  .pictureOne {
    grid-row: 2;
  }
  .pictureTwo {
    grid-column: 1;
    grid-row: 3;
  }
  #slide-container {
    grid-column: 1;
  }
}

@media screen and (max-width: 430px) {
  .routeInfoContainer {
    margin-top: 20px;
    margin-right: 2%;
    margin-left: 2%;
  }
  
  
}
